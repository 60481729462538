<template>
    <footer>
        <p>{{$t('calculator.footer.disclaimer')}}</p>
        <p>{{$t('calculator.footer.financing')}}</p>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style scoped>
footer {
    height: 120px;
    padding: 1rem;
    background-color: #f5f5f5;
}

</style>