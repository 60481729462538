<template>
    <header>
        <h1>{{$t('calculator.header.title')}}</h1>
    </header>
</template>

<script>
export default {
    name: 'HeaderComponent'
}
</script>

<style scoped>
header {
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
}

header > h1 {
    margin: 0;
}
</style>