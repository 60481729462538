<template>
    <b-card class="container d-flex justify-content-center" bg-variant="light">
        <b-form class="content" @submit.prevent="handleSubmit">
            <b-form-group class="form-item-container" :label="$t('calculator.volumen')" label-for="volumen" label-cols-sm="5" label-align-sm="center" :state="isValidVolumen"
                    :invalid-feedback="$t('calculator.volumen-error')">
                <b-form-input id="volumen" type="number" min="40" max="500" v-model="form.volumen" :state="isValidVolumen" required></b-form-input>
            </b-form-group>

            <b-form-group class="form-item-container" :label="$t('calculator.sexo')" label-cols-sm="5" label-align-sm="center" v-slot="{ ariaDescribedby }">
                <b-form-radio-group class="form-item" :options="sexos" value-field="value" text-field="name"
                    :aria-describedby="ariaDescribedby" v-model="form.sexo" required></b-form-radio-group>
            </b-form-group>

            <b-form-group class="form-item-container" :label="$t('calculator.adquisicion')" label-cols-sm="5" label-align-sm="center" v-slot="{ ariaDescribedby }">
                <b-form-radio-group class="form-item" :options="adquisicion" value-field="value" text-field="name"
                    :aria-describedby="ariaDescribedby" v-model="form.adquisicion" required></b-form-radio-group>
            </b-form-group>

            <b-form-group class="form-item-container" label-cols-sm="5" label-align-sm="center" :state="isValidIMC" :invalid-feedback="$t('calculator.imc-error')">
                <template v-slot:label><span v-html="$t('calculator.imc')"></span></template>
                <b-form-input type="number" id="imc" min="10" max="99" v-model="form.imc" :state="isValidIMC" required></b-form-input>
            </b-form-group>
        </b-form>
    </b-card>
</template>

<script>
export default {
    name: 'CalculatorForm',
    emits: ['formUpdate'],
    data() {
        return {
            sexos: [{ value: 0, name: this.$t('calculator.sexo-mujer') }, { value: 1, name: this.$t('calculator.sexo-hombre') }],
            adquisicion: [{ value: 0, name: this.$t('calculator.adquisicion-opt1') }, { value: 1, name: this.$t('calculator.adquisicion-opt2') }],
            form: {
                volumen: null,
                sexo: null,
                adquisicion: null,
                imc: null
            },
            isValidVolumen: null,
            isValidIMC: null
        };
    },
    watch: {
        form: {
            handler() {
                this.validateVolumen();
                this.validateIMC();
                this.$emit('formUpdate', this.form);
            },
            deep: true
        }
    },
    methods: {
        validateVolumen() {
            if(this.form.volumen) {
                const volumen = parseFloat(this.form.volumen);
                this.isValidVolumen = volumen >= 40 && volumen <= 500;
            }

        },
        validateIMC() {
            if(this.form.imc) {
                const imc = parseFloat(this.form.imc);
                this.isValidIMC = imc >= 10 && imc <= 99;
            }
        },
        handleSubmit() {
            this.$emit('submitForm', this.form);
        }
    }
}
</script>

<style scoped>
.container {
    max-width: 60rem;
    margin: 1rem auto;
}
.form-item-container {
    margin: 1rem 0;
}

.form-item {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 992px) {
    .form-item {
        margin: 0;
    }
}
</style>