<template>
    <b-button class="mb-5" type="button" size="lg" variant="primary" :disabled="!isButtonEnabled" @click="handleClick">{{ $t('calculator.button') }}</b-button>
</template>

<script>
export default {
    name: 'CalculatorButton', 
    props: {
        isButtonEnabled: {
            type: Boolean,
            required: true
        }
    },
    emits: ['submitForm'],
    methods: {
        handleClick() {
            this.$emit('submitForm');
        }
    }
}
</script>