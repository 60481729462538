const BASE_URL_API = "http://localhost:8081/api";

export const API_URLS = {
    CALCULADORA: BASE_URL_API + "/calculadora",
};

export const DATOS = {
    CALC_VALOR_INIT: 2.2418861038106486,
    CALC_PESO_SEXO: -0.112631506234514,
    CALC_PESO_IMC: 0.008438516404718346,
    CALC_PESO_VOL: -0.00481309755193855,
    CALC_PESO_ADQ: 0.16099891045484283,
    CALC_ERROR: 0.23315937869773493,
    CALC_RANGO_REFERENCIA: 1.96,
    CALC_TABLA_VALORES: [
        {index: 1, valor: -0.091294, error: 0.128739},
        {index: 2, valor: 0.106944, error: 0.19325},
        {index: 3, valor: 0.087769, error: 0.209319},
        {index: 4, valor: -0.109153, error: 0.157928},
        {index: 5, valor: -0.110935, error: 0.145043},
        {index: 6, valor: -0.02791, error: 0.150496},
        {index: 7, valor: -0.077368, error: 0.122016},
        {index: 8, valor: 0.342383, error: 0.191575},
        {index: 9, valor: 0.171276, error: 0.171561},
        {index: 10, valor: -0.110417, error: 0.170693},
        {index: 11, valor: -0.100524, error: 0.152416},
        {index: 12, valor: -0.025504, error: 0.119712},
        {index: 13, valor: -0.033244, error: 0.148413},
        {index: 14, valor: 0.333168, error: 0.218295},
        {index: 15, valor: -0.107247, error: 0.152606},
        {index: 16, valor: -0.101452, error: 0.128326},
        {index: 17, valor: -0.383004, error: 0.256682}]
}
 