<template>
    <b-card class="container" border-variant="white" v-if="showTable">
        <b-table striped hover :items="items" :fields="fields" small></b-table>
    </b-card>
</template>

<script>
import { DATOS } from '@/config.js';

export default {
    name: 'CalculatorTable',
    props: {
        result: { type: String, required: true },
        error: { type: String, required: true },
        showTable: { type: Boolean, required: true }
    },
    data() {
        return {
            items: [],
            fields: [
                { key: 'index', label: this.$t('calculator.tabla-col-indice') },
                { key: 'valor', label: this.$t('calculator.tabla-col-valor') },
                { key: 'intervalo', label: this.$t('calculator.tabla-col-interval') }],
            tabla_valores: DATOS.CALC_TABLA_VALORES
        }
    },
    watch: {
        result: 'computedTable',
        error: 'computedTable',
        tabla_valores: {
            handler: 'computedTable',
            deep: true
        }
    },
    mounted() {
        this.computedTable();
    },
    methods: {
        computedTable() {
            this.items = this.tabla_valores.map(tabla => {
                const valor = (parseFloat(this.result) + parseFloat(tabla.valor));
                const error = Math.sqrt(tabla.error ** 2 + this.error ** 2);
                return {
                    index: tabla.index,
                    valor: valor.toFixed(2) + " ± " + error.toFixed(2),
                    intervalo: "[" + (valor - error * DATOS.CALC_RANGO_REFERENCIA).toFixed(2) + ", "+  (valor + error * DATOS.CALC_RANGO_REFERENCIA).toFixed(2) + "]"
                };
            });
        }
    },
}
</script>

<style scoped>
.container {
    max-width: 60rem;
    margin: 1rem auto;
}
@media (max-width: 992px) {
    .container {
        margin: 0;
    }
}
</style>