<template>
  <div class="container">
    <Header />
    <CalculatorMain class="content"/>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header/HeaderComponent.vue';
import CalculatorMain from '@/components/calculator/CalculatorMain.vue';
import Footer from '@/components/footer/FooterComponent.vue';
export default {
  name: 'CalculatorView',
  components: { Header, CalculatorMain, Footer}
}
</script>

<style scoped>
.container {
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--content-bg);
    color: var(--content-text);

}

.content {
    flex: 1;
}
</style>