import App from './App.vue'
import { createApp } from 'vue'
import { BButton, BCard, BCardText, BForm, BFormGroup, BFormInput, BFormRadio, BFormRadioGroup, BOverlay, BTable } from 'bootstrap-vue-next';
import { createI18n } from "vue-i18n";
import { createRouter, createWebHistory } from 'vue-router'

// Configure Bootstrap
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

// Configure i18n
// Import translations
import es from "./lang/es.json";
import en from "./lang/en.json";
const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { es, en },
  warnHtmlInMessage: 'off', // disable warning HTML in message
});


// Configure route
import { routes } from './routes';
const router = createRouter({
  history: createWebHistory(),
  routes
})

// Optionally install the BootstrapVue icon components plugin
const app = createApp(App);
app.component('b-button', BButton);
app.component('b-card', BCard);
app.component('b-card-text', BCardText);
app.component('b-form', BForm);
app.component('b-form-group', BFormGroup);
app.component('b-form-input', BFormInput);
app.component('b-form-radio', BFormRadio);
app.component('b-form-radio-group', BFormRadioGroup);
app.component('b-overlay', BOverlay);
app.component('b-table', BTable);
app.use(i18n);
app.use(router);
app.mount('#app');