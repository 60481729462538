<template>
    <b-card border-variant="white">
        <b-overlay :show="loading" rounded="sm">
            <CalculatorForm @formUpdate="updateForm" @submitForm="submitForm" />
        </b-overlay>
        <CalculatorButton :isButtonEnabled="isButtonEnabled" @submitForm="submitForm" />
        <b-card-text class="row align-items-center w-75 py-1 mx-auto" v-if="result">
            <span class="display-5">{{ result }} <span>&#177;</span> {{ error }}</span>
        </b-card-text>
        <CalculatorTable :result="result" :error="error" :showTable="showTable" />
    </b-card>
</template>

<script>
import { DATOS } from '@/config.js';
import CalculatorForm from '@/components/calculator/form/CalculatorForm.vue';
import CalculatorButton from '@/components/calculator/button/CalculatorButton.vue';
import CalculatorTable from '@/components/calculator/table/CalculatorTable.vue';

export default {
    name: 'CalculatorMain',
    components: { CalculatorForm, CalculatorButton, CalculatorTable },
    data() {
        return {
            loading: false,
            form: {
                volumen: null,
                sexo: null,
                adquisicion: null,
                imc: null
            },
            showTable: false,
            result: '',
            error: ''
        };
    },
    computed: {
        isButtonEnabled() {
            return !this.loading && (this.form.volumen !== null && this.form.sexo !== null && this.form.adquisicion !== null && this.form.imc !== null);
        }
    },
    methods: {
        updateForm(newForm) {
            this.form = newForm;
        },
        submitForm() {
            this.loading = true;
            this.calcularForm();
            this.showTable = true;
            this.loading = false;
        },
        calcularForm() {
            this.result = parseFloat(DATOS.CALC_VALOR_INIT);
            this.result += parseFloat(DATOS.CALC_PESO_VOL) * this.form.volumen; // Volumen
            this.result += this.form.sexo * parseFloat(DATOS.CALC_PESO_SEXO); // Sexo
            this.result += this.form.adquisicion * parseFloat(DATOS.CALC_PESO_ADQ) // Adquisicion
            this.result += parseFloat(DATOS.CALC_PESO_IMC) * this.form.imc; //IMC
            this.result = this.result.toFixed(2);
            this.error = parseFloat(DATOS.CALC_ERROR).toFixed(2);
        }
    }
}
</script>