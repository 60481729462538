<template>
    <div class="error">
        <h1>{{ $t('error.title') }}</h1>
        <p>{{ $t('error.message') }}.</p>
    </div>
</template>

<script>
export default {
    name: 'ErrorView'
}
</script>

<style scoped>
.error {
    text-align: center;
    margin-top: 2rem;
}
</style>